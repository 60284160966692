// src/dexieConfig.js
import Dexie from "dexie";

const db = new Dexie("FormDatabase");
db.version(1).stores({
  formData:
    "++id, gender, insuredPersonBirthDate, insuredPersonSocialRegime, insuredPersons, startDate, insuredSpouseBirthDate, insuredSpouseSocialRegime, childrenNumber, insuredFirstChildBirthDate, insuredFirstChildSocialRegime, insuredSecondChildBirthDate, insuredThirdChildBirthDate, insuredFourthChildBirthDate, firstName, lastName, postalCode, email, phoneNumber, soins, hospitalisation, dentaire, optique, selectedDate",
});

export default db;
