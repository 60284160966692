import React from "react";
import { Box, Typography, Card, CardContent, Grid } from "@mui/material";
import { styled } from "@mui/system";
import colors from "index.scss";
import coverityLogo from "../../assets/images/Coverity.png";
import spvieLogo from "../../assets/images/spvie.png";

const StyledCard = styled(Card)({
  borderRadius: "25px",
  overflow: "hidden",
  transition: "all 0.3s ease-in-out",
  backgroundColor: colors.white,
  border: `2px solid ${colors.secondary}`,
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: `0 12px 20px ${colors.disabled}`,
  },
});

const PlanTitle = styled(Typography)({
  fontSize: "1.2rem",
  fontWeight: "bold",
  color: colors.primary,
});

const PlanSubtitle = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 600,
  color: colors.secondary,
});

const Price = styled(Typography)({
  fontSize: "1.8rem",
  fontWeight: "bold",
  color: colors.primary,
});

const ApiResultsComponent = ({ results }) => {
  if (!results) {
    return <Typography>Loading results...</Typography>;
  }

  const getProductName = (category) => {
    switch (parseInt(category)) {
      case 8:
        return "Senior";
      case 25:
        return "Malin";
      case 33:
      case 35:
        return "Genius";
      case 42:
        return "Nova";
      default:
        return "Unknown";
    }
  };

  // Combine and format Coverity results
  const coverityResults =
    results.coverity?.getTarifSanteResult?.formules?.map((formule) => ({
      provider: "Coverity",
      logo: coverityLogo,
      assureur: formule.assureur?.nom || "Unknown",
      planName: formule.libelle || "Unknown",
      monthlyPrice: formule.prime_mensuelle_ttc || 0,
      annualPrice: formule.prime_annuelle_ttc || 0,
    })) || [];

  // Combine and format SPVIE results
  const spvieResults = Object.entries(results.spvie || {}).flatMap(
    ([category, categoryResults]) =>
      (categoryResults?.Prices || []).map((price) => ({
        provider: "SPVIE",
        logo: spvieLogo,
        assureur: getProductName(category),
        planName: price.Level || "Unknown",
        monthlyPrice: price.Price || 0,
        annualPrice: (price.Price || 0) * 12,
      }))
  );

  // Combine all results and sort by monthly price
  const allResults = [...coverityResults, ...spvieResults].sort(
    (a, b) => a.monthlyPrice - b.monthlyPrice
  );

  if (allResults.length === 0) {
    return <Typography>No results available.</Typography>;
  }

  return (
    <Box sx={{ maxWidth: "1200px", margin: "auto", padding: "2rem" }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          fontWeight: "bold",
          color: colors.secondary,
          marginBottom: "2rem",
        }}
      >
        Vos résultats ({allResults.length} options)
      </Typography>
      <Grid container spacing={3}>
        {allResults.map((result, index) => (
          <Grid item xs={12} key={`result-${index}`}>
            <StyledCard>
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "1.5rem",
                }}
              >
                <Box>
                  <img
                    src={result.logo}
                    alt={result.provider}
                    style={{ height: "75px", marginBottom: "0.5rem" }}
                  />
                  <PlanTitle>{result.assureur}</PlanTitle>
                  <PlanSubtitle>{result.planName}</PlanSubtitle>
                </Box>
                <Box sx={{ textAlign: "right" }}>
                  <Price>
                    {result.monthlyPrice.toFixed(2)} €{" "}
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{ color: colors.black }}
                    >
                      /mois
                    </Typography>
                  </Price>
                  <Typography
                    variant="body2"
                    sx={{ color: colors.secondary, fontWeight: "bold" }}
                  >
                    {result.annualPrice.toFixed(2)} € /an
                  </Typography>
                </Box>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ApiResultsComponent;
